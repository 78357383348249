<template>
  <v-card
      dark
      :loading  = "this.$store.state.scan.qr_event_data.r"
      :disabled = "this.$store.state.scan.qr_event_data.r"
      class="mx-auto my-3"
      :max-width="getSettings.size.form_width"
  >
    <template slot="progress">
      <v-progress-linear
          color="orange"
          height="10"
          indeterminate
      ></v-progress-linear>
    </template>


    <v-card-title
        class="green darken white--text">
      Репертуар

      <v-spacer></v-spacer>

      <v-btn
          color="white"
          class="text--primary"
          fab
          x-small
          @click="onReloadAfisha"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>



    <v-list-item>

      <v-list-item-content>

        <div class="overline mb-0">
          Активный спектакль
        </div>

        <v-list-item-title class="headline mb-0">
          {{ $store.state.scan.qr_event_data.show }} [{{ $store.state.scan.qr_event_data.event }}]
        </v-list-item-title>

        <v-list-item-subtitle>{{ $store.state.scan.qr_event_data.date }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-btn
            icon
            class="text--primary mt-4"
            to="/stat"
        >
          <v-icon color="blue lighten-1">mdi-information-outline</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>







<!--    <v-card-text class="pt-4">-->
<!--      <b>Активный спектакль</b><br>-->
<!--      {{ $store.state.qr_event_data.show }} [{{ $store.state.qr_event_data.event }}] <br>-->
<!--      {{ $store.state.qr_event_data.date }}-->
<!--    </v-card-text>-->

    <v-divider></v-divider>

    <v-virtual-scroll
        v-if="this.$store.state.scan.eventAfishaScan.b"
        :items="this.$store.state.scan.eventAfishaScan.data.events"
        :item-height="70"
        :height="getVirtualScrollHeight"
    >

        <template v-slot:default="{ item }">
          <v-list-item :class="{'red darken-4': item.id==$store.state.scan.qr_event_data.event}">
  <!--          <v-list-item-avatar rounded size="30">-->
  <!--            <v-img-->
  <!--                :src="item.avatar"-->
  <!--            ></v-img>-->
  <!--          </v-list-item-avatar>-->

            <v-list-item-icon >
              <v-icon>mdi-clock</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{item.show_name}} ({{item.show_age}})</v-list-item-title>

              <v-list-item-subtitle v-text="item.timedate"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click="downloadEvent(item.id)">
                <v-icon color="amber darken-3">mdi-cloud-download</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>


    </v-virtual-scroll>

    <div class="text-center ma-10 pa-1" v-else>
      <v-progress-circular
          :size="70"
          :width="7"
          color="red"
          indeterminate
      ></v-progress-circular>

    </div>

  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Begin",

  data: () => ({

    loading: false,
    selectedItem: 1,

  }),

  computed:{

    ...mapGetters([
      "getSettings",
    ]),

    getVirtualScrollHeight(){
      console.log('111');

      let height = document.getElementsByClassName('v-main__wrap')['0'].clientHeight-195
      console.log(height);

      return height;
    }

  },

  methods: {
    onReloadAfisha() {
      this.$store.dispatch('loadAfishaScan');
      // и продавцов заодно передернуть, но лучше их на странице статистике показывать и управлять
      this.$store.dispatch('loadMongerList');
    },

    downloadEvent(id){
      console.log(id);
      this.$store.dispatch('loadEventScan', id);
    },

  }

}

</script>

<style scoped>
.border {
  border-inline: 3px dashed orange;
}
</style>
